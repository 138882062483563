
import { Common } from '@/common';
import { IGlossaryItem } from '@/common/types';
import { Clinic, processMessageTemplate } from '@/modules/clinic';
import { IExternalMessage, IExternalMessageTemplate, IMessagesFilter } from '@/modules/clinic/types';
import { createSearch, System } from '@/modules/system';
import { INotification } from '@/modules/system/types';
import $app from '@/plugins/modules'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient } from '../types';

@Component
export default class CustomerMainTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  system = $app.module(System);
  clinic = $app.module(Clinic);
  common = $app.module(Common);

  showAttractor = false;
  processAttractor = false;
  selectedAttractor: number | null = null;

  notifyCache: Array<INotification> | null = null
  currentNotification: INotification | null = null
  showNotification = false
  openNotification = false
  loadingNotifications = false
  processingNotification = false

  groupsCache: Record<string, IExternalMessageTemplate> = {}
  messagesCache: Array<IExternalMessage> | null = null
  currentMessage: IExternalMessage | null = null
  openMessage = false
  loadingMessages = false
  processingMessages = false

  get notifications(): Array<INotification> {
    this.loadNotifications()
    return this.notifyCache || [];
  }

  get messages(): Array<IExternalMessage> {
    this.loadMessages()
    return this.messagesCache || [];
  }

  get groupsNVD(): Array<string> {
    if (this.patient && this.patient.patientGroups) {
      return this.patient.patientGroups.split(';')
    } else {
      return []
    }
  }

  set groupsNVD(value: Array<string>) {
    if (this.patient) {
      let grp = ''
      if (value) {
        value.forEach(s => {
          if (s && s.trim() !== '') {
            grp += s + ';'
          }
        })
      }
      this.patient.patientGroups = grp
    }
  }

  get groupListNVD() {
    return [
      { code: '11', label: $app.i18n('nvd.person.groups.11') },
      { code: '12', label: $app.i18n('nvd.person.groups.12') },
      { code: '13', label: $app.i18n('nvd.person.groups.13') }
    ]
  }

  changeAttractor() {
    this.selectedAttractor = null;
    this.showAttractor = true;
  }

  createNotification() {
    if (this.patient && this.patient.patientId) {
      this.currentNotification = {
        id: null,
        type: 'i',
        companyId: 0,
        state: 'p',
        patientId: this.patient.patientId,
        userId: null,
        userName: null,
        text: ''
      };
      this.openNotification = true;
    }
  }

  editNotification(item: INotification) {
    this.currentNotification = item;
    this.openNotification = true;
  }

  async loadNotifications() {
    if (!this.notifyCache && this.patient && this.patient.patientId) {
      this.loadingNotifications = true;
      try {
        let list: Array<INotification> = await $app.get('/api/notifications?patient=' + this.patient.patientId);
        if (list) {
          this.currentNotification = null;
          list = list.filter(notif => notif.state !== 'r')
          list.forEach(notify => {
            if (!this.currentNotification && notify.state === 'p' && (!notify.userId || notify.userId === this.system.$store.getCurrentUser.id)) {
              this.currentNotification = notify;
              this.showNotification = true;
            }
          })
        }
        this.notifyCache = list
      } catch (err) {
        $app.pushError(err);
      }
      this.loadingNotifications = false;
    }
  }

  readNotification() {
    if (this.currentNotification) {
      this.currentNotification.state = 'r'
      this.showNotification = false;
      this.storeNotification()
    }
  }

  async deleteNotification() {
    if (this.currentNotification) {
      this.processingNotification = true;
      try {
        await $app.delete('/api/notifications/' + this.currentNotification.id)
        this.notifyCache = null
        await this.loadNotifications()
        this.openNotification = false;
      } catch (err) {
        $app.pushError(err);
      }
      this.processingNotification = false;
    }
  }

  async storeNotification() {
    this.processingNotification = true;
    try {
      await $app.post('/api/notifications', this.currentNotification)
      this.notifyCache = null
      await this.loadNotifications()
      this.openNotification = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processingNotification = false;
  }

  loadMessages() {
    if (!this.messagesCache) {
      this.reloadMessages()
    }
  }

  async reloadMessages() {
    if (this.patient && this.patient.patientId) {
      this.loadingMessages = true;
      try {
        const index: Record<string, IExternalMessageTemplate> = {}
        const groups: Array<IExternalMessageTemplate> = await $app.get('/api/ex-messages/templates')
        if (groups) {
          groups.forEach(g => {
            index['_global.sms.templates.text.' + g.code] = g
          })
        }
        this.groupsCache = index;

        const filter: IMessagesFilter = createSearch(100, 100, 'MessagePlanned', false, {
          filter: null,
          state: 'r',
          patientId: this.patient.patientId,
          to: new Date(3000, 1, 1, 0, 0, 0, 0),
          from: new Date()
        })

        const list: Array<IExternalMessage> = await this.clinic.$store.loadMessages(filter)
        this.messagesCache = list || []
      } catch (err) {
        $app.pushError(err);
      }
      this.loadingMessages = false;
    }
  }

  templateText(template: string, item: IExternalMessage): string {
    if (item && this.patient && this.patient.patientId) {
      const group = this.groupsCache[template]
      if (group) {
        const text = group.text[this.patient.languageCode || 'en']
        return processMessageTemplate(text, item)
      }
    }
    return '';
  }

  createMessage() {
    if (this.patient && this.patient.patientId) {
      this.currentMessage = {
        messageId: null,
        messageState: 'r',
        messageCreated: new Date(),
        messageIniciator: null,
        userName: null,
        messagePlanned: new Date().native(false),
        messageVisit: null,
        messagePatient: this.patient.patientId,
        patientFirstName: this.patient.patientFirstName,
        patientLastName: this.patient.patientLastName,
        messageTemplate: null,
        messageTemplateName: null,
        messageSent: null,
        messagePhone: this.patient.patientPhone,
        messageText: null,
        messageError: null,
        companyPhone: null,
        visitTimeFrom: null,
        doctorName: null
      };
      this.openMessage = true;
    }
  }

  get lang(): string {
    return this.common.$store.appLanguage.code
  }

  get templates(): Array<IGlossaryItem<string>> {
    return Object.keys(this.groupsCache).map(k => { return { code: k, label: this.groupsCache[k].title[this.lang], icon: '', color: '' } })
  }

  get planned(): string {
    return new Date(this.currentMessage && this.currentMessage.messagePlanned ? this.currentMessage.messagePlanned : '').native(false);
  }

  set planned(value: string) {
    if (this.currentMessage) {
      this.currentMessage.messagePlanned = new Date(value).native(false)
    }
  }

  cancelMessage(item: IExternalMessage) {
    item.messageState = 'l'
    this.storeMessage(item)
  }

  addMessage() {
    this.storeMessage(this.currentMessage)
  }

  copyTemplateText() {
    if (this.currentMessage) {
      this.currentMessage.messageText = this.templateText(this.currentMessage.messageTemplate || '', this.currentMessage)
      this.currentMessage.messageTemplate = null
    }
  }

  async storeMessage(item: IExternalMessage | null) {
    if (item) {
      this.processingMessages = true;
      try {
        await this.clinic.$store.storeMessage(item);
        await this.reloadMessages()
        this.openMessage = false
      } catch (err) {
        $app.pushError(err);
      }
      this.processingMessages = false;
    }
  }

  async setAttractor(withAttract: boolean) {
    if (this.patient) {
      this.processAttractor = true;
      try {
        await $app.post('/api/patients/' + this.patient.patientId + '/attractors/' + this.selectedAttractor + '/' + withAttract, {});
        this.patient.patientAttrator = this.selectedAttractor;
        this.showAttractor = false;
      } catch (err) {
        $app.pushError(err);
      }
      this.processAttractor = false;
    }
  }
}
